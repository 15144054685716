import Excel from "exceljs";

// Esta función debe recibir un archivo excel  y desglozar todos los datos y enviarlos en un array
export async function getRowsToArrayProducts(file) {
  console.log("funcion")
  const reader = new FileReader();
  const wb = new Excel.Workbook();
  reader.readAsArrayBuffer(file);
  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const buffer = reader.result;
      let rowsToArray = [];
      wb.xlsx
        .load(buffer)
        .then((workbook) => {
          workbook.worksheets[0].eachRow((row, rowNumber) => {
            try {
              if (rowNumber !== 1) {
                let rowToArray = {};
                console.log(rowNumber)
                console.log(row.values)
                rowToArray["code"] = row.values[1].toString();
                rowToArray["supplier"] = row.getCell(2).text;
                rowToArray["brand"] = row.getCell(3).text;
                rowToArray["family"] = row.values[4];
                rowToArray["margin"] = typeof (row.values[5]) === "number" ? row.values[5] : 0;

                Object.keys(rowToArray).forEach((key) => {
                  if (rowToArray[key] === null || rowToArray[key] === undefined || rowToArray[key] === "") {
                    reject(`Error en la fila ${[rowNumber]} del archivo`);
                  }
                });
                rowsToArray.push(rowToArray);
              }
            } catch (error) {
              reject(error)
            }
          });
        })
        .then(() => {
          resolve(rowsToArray);
        });
    };
  });
};

export async function getRowsToArray(file, typeOfUpload) {
  const reader = new FileReader();
  const wb = new Excel.Workbook();
  reader.readAsArrayBuffer(file);
  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const buffer = reader.result;
      let rowsToArray = [];
      let headers = [];
      wb.xlsx
        .load(buffer)
        .then((workbook) => {
          workbook.worksheets[0].eachRow((row, rowNumber) => {
            if (rowNumber === 1) {
              headers = row.values;
              headers = headers.map((header) => {
                if (typeOfUpload === "Productos") {
                  if (header === "Código") {
                    return "code";
                  } else if (header === "Descripción") {
                    return "description";
                  } else if (header === "Marca") {
                    return "brand";
                  } else if (header === "Precio RM y V") {
                    return "priceRM";
                  } else if (header === "Precio Maule") {
                    return "priceMaule";
                  } else if (header === "Precio Coquimbo") {
                    return "priceCoquimbo";
                  } else if (header === "Precio O´Higgins") {
                    return "priceVI";
                  } else if (header === "Precio Constitución") {
                    return "priceConstitucion";
                  } else if (header === "Precio Ñuble") {
                    return "priceÑuble";
                  } else if (header === "Precio Bio Bio") {
                    return "priceBiobio";
                  } else if (header === "Precio Araucanía") {
                    return "priceAraucania";
                  } else if (header === "Los Ríos Los Lagos") {
                    return "priceLosRios";
                  } else if (header === "Precio Chiloé") {
                    return "priceChiloe";
                  } else if (header === "Precio Copiapó") {
                    return "priceCopiapo";
                  } else if (header === "Precio Vallenar") {
                    return "priceVallenar";
                  } else if (header === "Precio Caldera") {
                    return "priceCaldera";
                  } else {
                    console.log(header);
                    reject("Error en el formato del encabezado");
                  }
                }
              });
            }
            if (rowNumber > 1 && typeOfUpload === "Productos") {
              let rowToArray = {};
              for (let i = 0; i < headers.length; i++) {
                if (headers[i] === "code") {
                  rowToArray[headers[i]] = row.values[i].toString();
                } else if (
                  headers[i] === "description" ||
                  headers[i] === "brand"
                ) {
                  rowToArray[headers[i]] = row.getCell(i).text;
                } else {
                  if (row.values[i] && typeof row.values[i] !== "number") {
                    reject(
                      "Error en el formato de los datos en la fila " + rowNumber
                    );
                  } else {
                    rowToArray[headers[i]] = row.values[i];
                  }
                }
              }
              rowsToArray.push(rowToArray);
            }
            if (rowNumber > 1 && typeOfUpload === "Clientes") {
              let rowToArray = {};

              rowToArray["city"] = row.values[6];
              rowToArray["commune"] = row.values[8];
              rowToArray["address"] = row.values[16];
              rowToArray["paymentForm"] = row.values[25];
              rowToArray["name"] = row.values[32];
              rowToArray["rut"] = row.values[41];
              rowToArray["socialName"] = row.values[42];
              rowToArray["region"] = row.values[43];
              if (
                rowToArray["rut"] === null ||
                rowToArray["rut"] === undefined
              ) {
                reject(`Error en la fila ${[rowNumber]} del archivo`);
              }
              rowsToArray.push(rowToArray);
            }

            if (rowNumber > 1 && typeOfUpload === "Stock") {
              let rowToArray = {};
              rowToArray["code"] = row.values[1].toString();
              rowToArray["description"] = row.getCell(2).text;
              rowToArray["stockTotal"] = row.values[3];
              rowToArray["stockArauco"] = row.values[4];
              rowToArray["stockSanAntonio"] = row.values[5];

              rowsToArray.push(rowToArray);
            }
          });
        })
        .then(() => {
          resolve(rowsToArray);
        });
    };
  });
}

export function returnExistingProducts(products, productsFromExcel) {
  let existingProducts = [];
  productsFromExcel.forEach((productFromExcel) => {
    products.forEach((product) => {
      if (productFromExcel.code === product.code) {
        existingProducts.push(productFromExcel);
      }
    });
  });
  return existingProducts;
}
